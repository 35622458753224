import { createTranslator } from 'next-intl';

import components from '../../messages/components.json';
import messages from '../../messages/messages.json';
import metadata from '../../messages/metadata.json';
import pages from '../../messages/pages.json';
import utils from '../../messages/utils.json';

const i18n = {
  t: createTranslator({
    locale: 'fa',
    messages: { pages, components, utils, messages, metadata },
  }),
};

export default i18n;
